.ui-widget {
	font-family: inherit;
	
	&.ui-autocomplete {
		padding: $spacer / 2 0;
		border: none;
		font-size: $font-size-base;
		
		@include box-shadow($box-shadow);
		@include border-radius(0 0 $border-radius $border-radius);
		
		& .ui-menu-item-wrapper {
			padding: $nav-link-padding-y $nav-link-padding-x;
			font-weight: $font-weight-bold;
			color: $dropdown-link-color;
			
			&.ui-state-active {
				background: $form-component-active-bg;
				border: none;
				color: $white;
				margin: 0;
				
				@include border-radius(0);
			}
		}
	}
}