.bootstrap-calendar {
	& .calendar {
		background: $white;
		border: none;
		padding: rem(7px) 0 rem(10px);
		
		@include border-radius(0);
		
		& .table {
			&.header {
				padding: 0;
				margin: 0 0 rem(2px);
				position: relative;
				
				@include box-shadow(none);
				
				& td {
					width: rem(45px);
					padding: rem(5px) rem(15px);
					vertical-align: middle;
					border: none;
					
					&.year {
						width: calc(100% - 90px);
					}
					
					& .visualmonthyear, {
						height: 100%;
						font-size: rem(16px);
						font-weight: 600;
						
						@include display-flex();
						@include flex-align(center);
						@include flex-justify-content(center);
					}
					& .icon-arrow-right,
					& .icon-arrow-left {
						cursor: pointer;
						font-size: rem(14px);
						height: 100%;
						color: $gray-500;
						
						@include display-flex();
						@include flex-align(center);
						@include flex-justify-content(center);
						@include fontawesome();
						
						&:hover,
						&:focus {
							color: $dark;
						}
					}
					& .icon-arrow-left {
						&:before {
							content: '\f060';
						}
					}
					& .icon-arrow-right {
						&:before {
							content: '\f061';
						}
					}
				}
			}
			&.daysmonth {
				margin: 0;
				
				& tr {
					& td {
						border: none;
						padding: rem(7px) rem(10px);
						font-weight: 600;
						color: $gray-600;
						
						
						& a {
							text-shadow: none;
							width: rem(26px);
							height: rem(26px);
							line-height: rem(26px);
							margin: rem(-4px) auto;
							
							@include border-radius($border-radius * 5);	
							@include display-flex();
							@include flex-align(center);
							@include flex-justify-content(center);
						}
						
						&.event {
							background: none;
							
							@include box-shadow(none);
							
							& a {
								color: $white;
							}
						}
					}
					
					&.week_days {
						& td {
							background: none;
							color: $gray-800;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
}