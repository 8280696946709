.irs {
	font-family: inherit !important;
	
	& .irs {
		& .irs-line {
			height: rem(4px);
			top: rem(30px);
		
			& .irs-line-left,
			& .irs-line-mid,
			& .irs-line-right {
				background: $gray-400;
				height: rem(4px);
			}
			& .irs-line-left {
				@include border-radius($border-radius 0 0 $border-radius);
			}
			& .irs-line-right {
				@include border-radius(0 $border-radius $border-radius 0);
			}
		}
		& .irs-to,
		& .irs-from, 
		& .irs-single {
			background: $gray-800;
		}
	}
	
	&.irs--flat {
		& .irs-bar {
			height: rem(4px);
			top: rem(30px);
			background: $form-component-active-bg;
		}
		& .irs-handle {
			height: rem(14px);
			top: rem(25px);
			
			& > i {
				&:first-child {
					background: $form-component-active-bg;
					width: rem(4px);
					margin-left: rem(-2px);
				}
			}
			&:hover,
			&:focus,
			&.state_hover {
				& > i {
					&:first-child {
						background: shade-color($form-component-active-bg, 25%);
					}
				}
			}
		}
	}
	&.irs--big {
		height: rem(40px);
		
		& .irs {
			& .irs-line {
				background: lighten($dark, 70%);
				border: none;
			}
		}
		& .irs-bar {
			height: rem(4px);
			top: rem(30px);
			background: $form-component-active-bg;
			
			@include box-shadow(none);
		}
		& .irs-handle {
			top: rem(21px);
			width: rem(20px);
			height: rem(20px);
			background: $white;
			border: none;
			
			@include box-shadow(0 2px 5px rgba($gray-900, .2));
			
			&:hover,
			&:focus,
			&.state_hover {
				background: $white;
				
				@include box-shadow(0 2px 5px rgba($gray-900, .4));
			}
		}
	}
	&.irs--flat,
	&.irs--big,
	&.irs--modern,
	&.irs--sharp,
	&.irs--round,
	&.irs--square {
		& .irs-from,
		& .irs-to,
		& .irs-single {
			font-size: $font-size-sm;
			font-weight: $font-weight-bold;
			
			&:before {
				border-top-color: $gray-800;	
			}
		}
		& .irs-min,
		& .irs-max {
			color: $dark;
			font-size: $font-size-sm;
			font-weight: $font-weight-bold;
			background: $gray-300;
		}
	}
}