﻿.standard-table {
    border-bottom: 1px #ddd solid;
}

.standard-header {
    background-color: #eee;
}

.standard-table div {
    text-align: center;
}
