$ebok-color: #1474ab;
$ebok-light-color: #1474ab2f;
$ebok-background: #fff;

* {
    font-size: 99,4%;
  }

body {
	background-color: $ebok-background  !important;
}

.ebok-color-text {
	color: $ebok-color;
}

.navbar-logo {
	border: none !important;
	background-image: url('../assets/img/ZGKLogo1.png') !important; 
	background-size: cover !important;
	background-color: transparent !important;
    width: 265px;
    height: 56px;
    display: inline-block !important;
}
// .app-top-menu { 
// 	background: $ebok-background !important;
// 	& .menu-link {
// 		color: $ebok-color !important;
// 	}

// 	& .menu {
// 		border-bottom-width: 1px !important;
// 		border-color: black !important;
// 		& .menu-item {
// 			display: inline-block !important;
// 		}
// 		& .menu-item.active {					
// 			> .menu-link {				
// 				background: $ebok-color !important;
// 				color: $ebok-background !important;
// 			}
// 		}
// 	}
// }

.panel {
    background: $ebok-background !important;
    border: solid !important;
    border-width: 1px !important;
    border-color: $ebok-color !important;
    min-height: 312px; 
}

.panel-heading {
	background: $ebok-background !important;
	color: $ebok-color !important;
	border: solid !important;
	border-width: 0px !important;
	border-bottom-width: 1px !important;
	border-color: $ebok-light-color !important;
}

.card {
	background: $ebok-background !important;
	color: black;
	border: none;
}

.card-header {
	padding-left: 0px;
	background: $ebok-background !important;
	color: black;
	border: none;
}

.page-header {
	color: $ebok-color !important;
	font-size: 18px;
	margin: 0 0 15px;
	padding: 0;
	border: none;
	line-height: 32px;
	font-weight: 500;
  }
 
  .form-label { 
	color: $ebok-color;
  }

.app-test {	
    display: flex;
    flex-direction: column;

}
.content-test {
	flex: 1 1 100%;
}
//   .navbar-nav{
// 	display: inline-block !important;
// 	 text-align: right  !important;
//   }

//   .app-header .navbar-nav .navbar-item .navbar-link {
// 	color: $ebok-color !important;
// 	display: inline-block !important;
// }
.app-with-top-menu {
	padding-top: 0px;
}
.navbar {
	background: $ebok-background !important;

}
.navbar-container {
	max-width: unset;
}
// .menu-container {
// 	border-bottom-width: 1px !important;
// 	border-color: #1474ab2f !important;
// 	border-bottom-style: solid;
// 	height: 65px;
// }
.nav-user-profile {
	display: flex; 
	justify-content: flex-end;
}
.nav-dropdown-user-profile {
	min-width: 150px;
}
.nav-link {
	font-size: 19px;
	color: #1474ab !important;
}
.navbar-toggler {
	color: $ebok-color !important;
	border-color: $ebok-color !important;
}
.navbar-toggler-icon {
	border-color: $ebok-color !important;
}
.dropdown-menu .dropdown-item {
	color: $ebok-color !important;
  }

  .standard-table div {
	color: $ebok-color !important;
  }

  .form-select {
	color: $ebok-color !important;
  }

.form-control {
	color: $ebok-color !important;
  }

  .simple-text {
	color: $ebok-color !important;
	display: inline-block !important;
  }

  .simple-text2 {
	color: $ebok-color !important;
	display: inline-block !important;
	margin-left: 200px;
	margin-right: 5px; 
  } 

  .card-header {
	color: $ebok-color !important;
  }

  .p-3 {
	color: $ebok-color !important;
}


.btn-primary {
	color: #FFFFFF;
	background-color: $ebok-color !important;
	border-color: $ebok-color !important;
}

.btn-default {
	color: #FFFFFF;
	background-color: $ebok-color !important;
	border-color: $ebok-color !important;
}

.btn-default:hover {
	color: #FFFFFF;
	background-color: $ebok-color !important;
	border-color: $ebok-color !important;
}

//our css
.line-spacer {
	border-top: 1px solid $ebok-color;
}

.text-align-end {
	text-align: end;
}

//home start
.home-comp-panel {
	//overflow-y: scroll;
	height: 312px; 
}

.home-comp-panel-contracts {
	height: 200px; 
	min-height: 200px;
}

.home-comp-panel-body {
    overflow-y: auto;
}
//home end

//declaration-box-item start
.declaration-box-item-card {
	padding: 0px; 
	margin: 0px;
}

.declaration-box-item-floating-label {
	margin: 0px !important;
	padding-bottom: 3px;
}

.contract-details {
	margin-left: 10px;
	margin-right: 10px;
	height: auto;
}

.customer-selector {
	padding: 0px;
}

.cursor-pointer {
	cursor: pointer;
}

.to-right {
	text-align: end !important;
}

.currency {
	text-align: end !important;
}

.textarea-min-height {
	min-height: 200px !important;
	height: auto !important;
}

//declaration-box-item end