.app-without-sidebar {
	& .app-content {
		margin: 0;
	}
	&:not(.app-with-top-menu) .app-header {
		& .navbar-brand {
			@include media-breakpoint-down(sm) {
				@include flex-justify-content(center);
			}
		}
	}
}