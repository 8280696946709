﻿.test {
    divTest {
        display: inline-block;
    }

    #parent {
        width: 100%;
        height: 90px;
        white-space: nowrap;
        overflow-x: auto;
    }

    .child {
        display: inline-block;
        width: 50%;
        height: 100%;
        background-color: red;
    }
}
